<template>
    <el-row :gutter="20">
        <template v-for="(item,index) in works_list">
            <el-col 
                :span="6"
                v-if="index <= 3"
                :key="item.id">
                <center-member-works-upload
                    :style-id="styleId"
                    :image-id="item.mid"
                    :image-url="item.url">
                </center-member-works-upload>
            </el-col>
        </template>
    </el-row>
</template>

<script>
export default {
    data() {
        return {
            works_list: [],
        }
    },
    props: {
        "imagesList": Array,
        "styleId": String
    },
    components: {
        centerMemberWorksUpload: () => import("@/components/centerMemberWorksUpload")
    },
    methods: {
        setList(val){
            if(val.length && val.length>0){
                this.works_list = val;
                let new_obj = {
                    mid: "",
                    url: "",
                },
                new_arr = [],
                num = 4 - val.length;
                for(let i = 0; i < num; i++){
                    this.works_list.push(new_obj);
                }
            }
        }
    },
    watch: {
        imagesList: {
            handler: "setList",
            immediate: true
        }
    },
}
</script>

<style scoped>

</style>